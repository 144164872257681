
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "Documentation",
  setup() {
    return {
      apiBaseUrl: ref(process.env.VUE_APP_QRNG_API_BASE_URL),
    };
  },
});
